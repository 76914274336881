import { Component, inject, Input } from '@angular/core';
import { addDoc, collection, Firestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent {
  @Input() color: 'gray' | 'white' = 'gray'
  @Input() columns: '1' | '2' | '3' = '1';
  contactFormData: any = {}
  firestore: Firestore = inject(Firestore);
  async sendContactForm() {

    if (this.contactFormData?.termAccepted && this.contactFormData?.email) {
      const col = collection(this.firestore, 'contact')
      await addDoc(col, { ...this.contactFormData });
      Swal.fire({
        icon: 'success',
        title: 'Obrigado!',
        text: 'Mensagem enviada com sucesso!',
      });
      this.contactFormData = {}
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, forneça um e-mail válido!',
      });
    }
  }

}
