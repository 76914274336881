<div class="star-rating relative">
  <div class="absolute z-1">
    <i
    class="fa fa-star"
    *ngFor="let star of stars | slice : 0 : 5; let i = index"
    ></i>
    <i class="fa fa-star-half" *ngIf="rating % 1 !== 0"></i>
  </div>
  <div class="absolute z-0">
    <i class="far fa-star" *ngFor="let star of [0, 1, 2, 3, 4]"></i>
  </div>
</div>
