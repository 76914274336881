<button
  [type]="type"
  [id]="id"
  [disabled]="isDisabled"
  [class]="buttonClass"
  [ngStyle]="{'width': buttonWidth }"
  [ngClass]="{
    'w-full': buttonWidth === 'full',
    'w-fit': buttonWidth === 'fit',
    'primary': theme === 'primary' && !isDisabled,
    'secondary': theme === 'secondary' && !isDisabled,
    'tertiary': theme === 'tertiary' && !isDisabled,
    'quaternary': theme === 'quaternary' && !isDisabled,
    'quintenary': theme === 'quintenary' && !isDisabled,
    'disabled': isDisabled,
  }"
>
  <span *ngIf="!isLoading">
    <i [class]="icon + ' ' + 'mr-2'" *ngIf="icon && iconPosition === 'left'"></i>
    <ng-content></ng-content>
    <i [class]="icon + ' ' + 'ml-2'" *ngIf="icon && iconPosition === 'right'"></i>
  </span>
  <span *ngIf="isLoading">
    <i class="fas fa-spinner fa-spin"></i>
  </span>
</button>
