import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../_models/user';
import { Observable, Subject, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

type tokenPayload = {
  access_token:string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticated:Subject<boolean> = new Subject<boolean>();
  private currentToken:string;


  constructor(
    private httpClient: HttpClient
  ) {
    var token = localStorage.getItem(btoa(environment.keys.token));
    if(token){
      this.currentToken = token;
    }
   }


  login(user:User):Observable<tokenPayload>{
    return this.httpClient.post<tokenPayload>(environment.apiUrl+'login',user).pipe(map((res:tokenPayload)=>{
      this.currentToken=res.access_token;
      localStorage.setItem(btoa(environment.keys.token), res.access_token);
      return res;
    }), tap(_=>{
      this.authenticated.next(true);
    }));
  }

  logout(){
    localStorage.removeItem(btoa(environment.keys.token));
    return true;
  }

  getCurrentToken(){
    return this.currentToken;
  }
  
}
