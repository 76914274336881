import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true
    }
  ]
})
export class InputComponent implements OnInit{
  //input parameters
  @Input() type: 'text' | 'number' | 'password' | 'date' | 'email' | 'tel' = 'text';
  @Input() name = '';
  @Input('inputId') inputId:string;
  @Input() control: FormControl<any> = new FormControl();
  @Input() tabIndex?: string;
  @Input() thousandSeparator = '';
  @Input() mask = '';
  @Input() decimalMarker = '';
  @Input() prefix = '';
  @Input() placeholder = '';
  @Input() inputClass =
    'block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-slate-200 appearance-none focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-40 peer';
  showPassword = false;
  //label parameters
  @Input() label = '';
  @Input() labelClass = 'text-[12px] font-medium mb-2';
  @Input() value = '';
  @Input() model:any;
  @Output() modelChange = new EventEmitter<any>();
  @Input() min = undefined;
  @Input() allowPositiveOnly = false;
  @Input() required = false;
  @Input() isDisabled = false;
  @Input() disableLabel:boolean = false;
  @Input() enableIconLabel:boolean = false;
  @Input() iconLabel:string = 'fal fa-info-circle';

  public style:string = "height: 40px !important;"

  @Output() iconLabelClicked: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.control) {
      throw new Error('FormControl is required for InputComponent');
    }
    if(!this.inputId || this.inputId==''){
      var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var autoId = '';
      for(var i=0; i<=10; i++){
        autoId += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
      this.inputId = autoId;
    }
  }

  changeModel(){
    this.modelChange.emit(this.model);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

  blockCharacters(event: KeyboardEvent) {
    if (this.allowPositiveOnly && event.key == '-') {
      event.preventDefault();
    }
  }

  onChange(event: any) {
    if (this.control) {
      this.control.setValue(event.target.value);
      this.control.markAsDirty();
      this.control.markAsTouched();
    }
  }

  emitClickIconLabel(){
    this.iconLabelClicked.next('');
  }
}
