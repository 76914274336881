import { Component, inject } from '@angular/core';
import { Firestore, addDoc, collection } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrl: './footer-home.component.scss',
})
export class FooterHomeComponent {
  contactFormData: any = {}
  firestore: Firestore = inject(Firestore);
  constructor() {
  }

  async sendContactForm() {

    if (this.contactFormData?.termAccepted && this.contactFormData?.email) {
      const col = collection(this.firestore, 'contact')
      await addDoc(col, { ...this.contactFormData });
      Swal.fire({
        icon: 'success',
        title: 'Obrigado!',
        text: 'Mensagem enviada com sucesso!',
      });
      this.contactFormData = {}
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor, forneça um e-mail válido!',
      });
    }
  }

  getYear(): string {
    return moment().format('YYYY')
  }
}
