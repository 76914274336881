import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileMenuItem } from '../mobile-menu/mobile-menu-item.interface';

@Component({
  selector: 'app-header-primary',
  templateUrl: './header-primary.component.html',
  styleUrl: './header-primary.component.scss'
})
export class HeaderPrimaryComponent implements OnInit{
  
  @Input() isBackEnabled? = true;
  public usuario:any = null;
  public enableMobileLayout:boolean=false;
  public mobileMenuOpened:boolean=false;
  public menuItems: Array<MobileMenuItem> = [
    {
      title: 'Página inicial',
      path: '/',
    },
    {
      title: 'Encontre seu investimento',
      path: '/investments',
    },
    {
      title: 'Quem somos',
      path: '/quem-somos',
    },
    {
      title: 'FAQ',
      path: '/faq',
    },
    {
      title: 'Glossário',
      path: '/glossario',
    },
    {
      title: 'Contato',
      path: '/contato',
    },
  ];
  public mobileMenuLinks: Array<MobileMenuItem> = [
    {
      title: 'Entre em Contato',
      description: 'contato@planetainvest.com.br',
      path: 'mailto:contato@planetainvest.com.br',
    },
  ];

  constructor(
    private deviceDetectorService:DeviceDetectorService,
    private location:Location,
    private router:Router
    ){
     
  }

  ngOnInit():void{
    if(this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet()){
      this.enableMobileLayout=true;
    }
  }
  
  back() {
    this.location.back();
  }

  toggleMenu(){
    
  }

  scrollBottom(){
    window.scrollTo(0, document.body.scrollHeight);
  }

  get routeUrl(){
    return this.router.url;
  }
}
