import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, ViewContainerRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { AcademySectionComponent } from '../public/home/components/academy-section/academy-section.component';
import { FindInvestmentComponent } from '../public/home/components/find-investment/find-investment.component';
import { HeroComponent } from '../public/home/components/hero/hero.component';
import { OpenFinanceTransparencyComponent } from '../public/home/components/open-finance-transparency/open-finance-transparency.component';
import { SimulateAndCompareComponent } from '../public/home/components/simulate-and-compare/simulate-and-compare.component';
import { ThoughtForYouComponent } from '../public/home/components/thought-for-you/thought-for-you.component';
import { AcademyFavoriteCardComponent } from './academy-favorite-card/academy-favorite-card.component';
import { AcademyHorizontalCardComponent } from './academy-horizontal-card/academy-horizontal-card.component';
import { ButtonComponent } from './button/button.component';
import { CardPlaceholderComponent } from './card-placeholder/card-placeholder.component';
import { CardsComponent } from './cards/cards.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FooterHomeComponent } from './footer-home/footer-home.component';
import { FooterComponent } from './footer/footer.component';
import { IndexersComponent } from './indexers/indexers.component';
import { InputComponent } from './input/input.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { NavbarHomeComponent } from './navbar-home/navbar-home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { SelectComponent } from './select/select.component';
import { SideAcademyComponent } from './side-academy/side-academy.component';
import { SideHomeComponent } from './side-home/side-home.component';
import { SidesectionComponent } from './sidesection/sidesection.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { HeaderPrimaryComponent } from './header-primary/header-primary.component';
import { ModalPrimaryComponent } from './modal-primary/modal-primary.component';




@NgModule({
  declarations: [
    NavbarComponent,
    HeaderPrimaryComponent,
    SplashScreenComponent,
    SidesectionComponent,
    SideAcademyComponent,
    CardsComponent,
    IndexersComponent,
    FooterHomeComponent,
    FooterComponent,
    SideHomeComponent,
    CardPlaceholderComponent,
    NavbarHomeComponent,
    MobileMenuComponent,
    ContactFormComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    PageTitleComponent,
    AcademyHorizontalCardComponent,
    AcademyFavoriteCardComponent,
    StarRatingComponent,
    ButtonComponent,
    HeroComponent,
    FindInvestmentComponent,
    ThoughtForYouComponent,
    OpenFinanceTransparencyComponent,
    SimulateAndCompareComponent,
    AcademySectionComponent,
    SpinnerComponent,
    ModalPrimaryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxTippyModule,
    RouterModule,
    RouterModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxChartsModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    NavbarComponent,
    SplashScreenComponent,
    SidesectionComponent, CardPlaceholderComponent,
    SideAcademyComponent,
    CardsComponent,
    IndexersComponent,
    FooterHomeComponent,
    FooterComponent,
    SideHomeComponent,
    NavbarHomeComponent,
    MobileMenuComponent,
    ContactFormComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    PageTitleComponent,
    AcademyHorizontalCardComponent,
    AcademyFavoriteCardComponent,
    StarRatingComponent,
    ButtonComponent,
    HeroComponent,
    FindInvestmentComponent,
    ThoughtForYouComponent,
    OpenFinanceTransparencyComponent,
    SimulateAndCompareComponent,
    AcademySectionComponent,
    SpinnerComponent,
    HeaderPrimaryComponent
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutModule { }
