export const environment = {
  env: 'local',
  production: false,
  // apiUrl: 'http://localhost:3000/',
  // apiPlanetaDomain:'localhost:3000',
  apiUrl: 'https://api-planeta-invest-qzhs.onrender.com/',
  apiPlanetaDomain:'api-planeta-invest-qzhs.onrender.com',
  firebaseConfig: {"projectId":"planeta-invest","appId":"1:620670161832:web:6dc6502746c1d81f99039d","storageBucket":"planeta-invest.appspot.com","apiKey":"AIzaSyAbOWX7XKlosJqwk-S75QyocxLdu8Npl34","authDomain":"planeta-invest.firebaseapp.com","messagingSenderId":"620670161832"},
  blogBaseUrl: 'https://planetainvest.blog/wp-json/wp/v2',
  keys:{
    token: 'token'
  }
};
