import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class MathService {

  /**
   * The function `annualInterestRateToMonthly` converts an annual interest rate to a monthly interest
   * rate using a mathematical formula.
   * @param {number} annualRate - The `annualRate` parameter represents the annual interest rate that you
   * want to convert to a monthly interest rate.
   * @returns The function `annualInterestRateToMonthly` takes an annual interest rate as input and
   * returns the equivalent monthly interest rate.
   */
  annualInterestRateToMonthly(annualRate: number): number {
    return Math.pow((1 + annualRate / 100), 1 / 12) - 1;
  }

  /**
   * The function calculates the number of periods needed to reach a future value based on present value,
   * monthly rate, and payment amount.
   * @param {number} presentValue - The presentValue parameter represents the current value of an
   * investment or loan. It is the initial amount of money involved in a financial transaction.
   * @param {number} futureValue - Future value is the value of an asset or investment at a specified
   * date in the future, based on an assumed rate of growth or return.
   * @param {number} interestRate - The `interestRate` parameter represents the annual interest rate for
   * the calculation. This rate is typically expressed as a decimal value. For example, the annual
   * interest rate is 5%.
   * @param {number} payment - The `payment` parameter in the `nper` function represents the amount of
   * money paid each period. This could be a monthly loan payment, a monthly investment contribution, or
   * any other regular payment amount.
   * @returns the number of periods (nper) required to reach the future value from the present value with
   * monthly payments at a given monthly interest rate.
   */
  nper(interestRate: any, payment: number, present: number, future: number, type?: any): number {
    var type = (typeof type == 'undefined') ? 0 : type;
    const monthlyRate = this.annualInterestRateToMonthly(interestRate);
    var future = (typeof future == 'undefined') ? 0 : future;

    var num = payment * (1 + monthlyRate * type) - future * monthlyRate;
    var den = (present * monthlyRate + payment * (1 + monthlyRate * type));
    return Math.round(Math.abs(Math.log(num / den) / Math.log(1 + monthlyRate)));
  }


  /**
   * The function calculates the payment amount based on present value, future value, monthly rate, and
   * number of periods.
   * @param {number} presentValue - The `presentValue` parameter represents the current value of an
   * investment or loan. It is the initial amount of money involved in a financial transaction.
   * @param {number} futureValue - Future value is the value of an investment or asset at a specified
   * date in the future.
   * @param {number} interestRate - The `interestRate` parameter represents the annual interest rate for
   * the calculation. This rate is typically expressed as a decimal value. For example, the annual
   * interest rate is 5%.
   * @param {number} numberOfPeriods - The `numberOfPeriods` parameter represents the total number of
   * payment periods. This could be the number of months, years, or any other unit of time over which the
   * payments are made.
   * @returns the payment amount based on the present value, future value, monthly interest rate, and
   * number of periods provided as input. If the number of periods is 0, it returns the sum of present
   * value and future value. Otherwise, it calculates the payment amount using the formula ((presentValue
   * + futureValue) / ((1 + monthlyRate) ** numberOfPeriods - 1)).
   */
  pgto(interestRate: number, numberOfPeriods: number, presentValue: number, futureValue: number, type?: any): number {
    const monthlyRate = this.annualInterestRateToMonthly(interestRate);

    // Verificação de argumentos
    if (isNaN(monthlyRate) || isNaN(numberOfPeriods) || isNaN(presentValue)) {
      console.log("Erro: Argumentos inválidos.");
    }
    // Se a taxa for zero, o pagamento é simplesmente o valor presente mais o valor futuro dividido pelo número de períodos
    if (monthlyRate === 0) {
      return -(presentValue + futureValue) / numberOfPeriods;
    }

    // Cálculo do fator de amortização
    const pvif = Math.pow(1 + monthlyRate, numberOfPeriods);

    // Cálculo do pagamento
    let pagamento = -monthlyRate * (presentValue * pvif + futureValue) / (pvif - 1);

    // Se o tipo for 1, o pagamento é feito no início do período, então é necessário ajustar o valor
    if (type === 1) {
      pagamento /= 1 + monthlyRate;
    }

    return pagamento;
  }

  pmt(interestRate: number, numberOfPeriods: number, presentValue: number, futureValue: number, type?: any): any {
    const monthlyRate = this.annualInterestRateToMonthly(interestRate);
    ;
    // Verificação de argumentos
    if (isNaN(monthlyRate) || isNaN(numberOfPeriods) || isNaN(presentValue)) {
      return "Erro: Argumentos inválidos.";
    }
    const taxaJuros = 1 + monthlyRate;

    // Fórmula do Excel
    if (type == 0) {
      return (
        presentValue *
        ((taxaJuros - 1) * Math.pow(taxaJuros, numberOfPeriods)) /
        (Math.pow(taxaJuros, numberOfPeriods) - 1)
      );
    } else if (type == 1) {
      return (
        presentValue *
        (taxaJuros - 1) *
        Math.pow(taxaJuros, numberOfPeriods) /
        (Math.pow(taxaJuros, numberOfPeriods) - 1) +
        presentValue
      );
    } else {
      return "Erro: Tipo de pagamento inválido.";
    }
  }


  /**
   * The function calculates compound interest on a series of installment payments over a specified
   * period of time.
   * @param {number} installmentValue - The `installmentValue` parameter represents the amount of money
   * paid in each installment.
   * @param {number} interestRate - The `interestRate` parameter in the `compoundInterest` function
   * represents the annual interest rate for the compound interest calculation. It is the rate at which
   * interest is calculated on the principal amount.
   * @param {number} time - The `time` parameter in the `compoundInterest` function represents the number
   * of time periods over which the compound interest will be calculated. It could be the number of
   * months, years, or any other unit depending on the context of the calculation.
   * @returns The function `compoundInterest` is returning the total amount after applying compound
   * interest for the given time period, minus the total amount of installment payments made during that
   * time.
   */  compoundInterest(installmentValue: number, interestRate: number, installments: number, presentValue?: number): number {
    const monthlyRate = this.annualInterestRateToMonthly(interestRate);
    let initialAmount = presentValue || 0;

    const finalAmount = installmentValue * ((1 + monthlyRate) ** installments - 1) / monthlyRate;
    return finalAmount - (installmentValue * installments) + initialAmount;
  }




  compoundInterest2(installmentValue: number, interestRate: number, installments: number, presentValue?: number): number {
    const monthlyRate = this.annualInterestRateToMonthly(interestRate);
    let initialAmount = presentValue || 0;

    let finalAmount;
    if (installmentValue > 0) {
       finalAmount = installmentValue * ((1 + monthlyRate) ** installments - 1) / monthlyRate;
    } else {
       finalAmount = initialAmount * Math.pow(1 + monthlyRate, installments);
    }

    return -(finalAmount - ((installmentValue * installments) + initialAmount));
  }

}