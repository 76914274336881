<article class="bg-gradient-to-r from-gray-900 to-gray-100 rounded-xl border shadow-md overflow-hidden">
  <div class="h-[8.25rem] px-8 py-4" style="background-image: url({{card.backgroundImage}}); background-position: center top; background-repeat: no-repeat; background-size: cover;">
    <span class="text-base text-white leading-5">{{card.title}}</span>
    <app-star-rating [rating]="card.rating"></app-star-rating>
    <div class="flex justify-between mt-8 text-sm text-white">
      <span>{{card.totalWatched * 100 / card.total | number:'1.0-0'}}%</span>
      <span>{{card.total === 60 ? card.total/60 + ' ' + 'hora' : card.total > 60 ? card.total/60 + ' ' + 'horas' : card.total + ' ' + 'minutos'}}</span>
    </div>
    <div class="w-full bg-white rounded-full h-2.5 mt-1">
      <div class="bg-blue-600 h-2.5 rounded-full" [ngStyle]="{'width': card.totalWatched * 100 / card.total + '%'}"></div>
    </div>
  </div>
  <div class="flex items-center justify-center bg-white p-8">
    <app-button>Continuar</app-button>
  </div>
</article>
