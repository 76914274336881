import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { LayoutModule } from './_layout/layout.module';
import { ScrollService } from './_services/scroll.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestInterceptor } from './_interceptors/request.interceptor';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NgxChartsModule,
    LayoutModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: '...' }),
    AppRoutingModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(), 
    NgxTippyModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circle,
      primaryColour: '#ffffff',
      secondaryColour: "#ccc",
    }),
    HttpClientModule,
    NgSelectModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig())
  ],
  providers: [provideEnvironmentNgxMask(),
    {
        provide: LOCALE_ID,
        useValue: 'pt'
    },
    {
        provide:  DEFAULT_CURRENCY_CODE,
        useValue: 'BRL'
    },
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi:true},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private scrollService: ScrollService) {
    this.scrollService.init();
    
  }
 }
