import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  // checkbox parameters
  @Input() name = '';
  @Input() id = '';
  @Input() checked = false;
  @Input() control = new FormControl();
  @Input() tabIndex?: string;
  @Input() CheckboxClass = 'w-4 h-6 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300';

  //label parameters
  @Input() label = '';
  @Input() labelClass = 'ms-1 text-[14px] text-justify';
}
