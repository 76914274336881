import { Component, Input } from '@angular/core';

type colorType = 'white' | 'black' | 'blue' | 'yellow' | 'red';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {

  /* Spinner size, using tailwind width and height */
  @Input('size') size:string = '5';

  /* Spinner color, using tailwind color */
  @Input('color') color:string | colorType = 'white';

  @Input('text') text:string;

}
