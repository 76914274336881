<div class="flex items-center">
  <input
    type="checkbox"
    [name]="name"
    [tabindex]="tabIndex"
    [formControl]="control"
    [ngClass]="{
    '!border-rose-500': control.dirty && control.invalid,
    '!border-green-500': control.dirty && !control.invalid,
            }"
    [class]="CheckboxClass"
  />
  <label [for]="name" [class]="labelClass">{{ label }}</label>
</div>
