
<div class="p-2 text-{{color}} flex flex-row justify-center items-center">
    <svg class="animate-spin w-{{size}} h-{{size}}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="currentColor"
            d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
            transform="rotate(360 12 12)" />
    </svg>
    <p *ngIf="text" class="m-0 ml-2 text-normal">
        {{text}}
    </p>
</div>