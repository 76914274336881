<div>
  <label *ngIf="!disableLabel" [for]="id" [class]="labelClass" [ngClass]="{'flex justify-between': enableIconLabel}">
    <span>
      {{ label }}
    </span>
    <span *ngIf="enableIconLabel">
      <i [class]="iconLabel+' hover:cursor-pointer hover:opacity-70'" (click)="emitClickIconLabel()"></i>
    </span>
  </label>
  <ng-select
    [items]="items"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [formControl]="control"
    [name]="name"
    [placeholder]="placeholder"
    [multiple]="isMultiple"
    [class]="selectClass"
    [clearable]="clearable"

  ></ng-select>
</div>
