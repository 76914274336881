<div class="flex flex-col relative">
  <label *ngIf="!disableLabel" [for]="inputId" [class]="labelClass" [ngClass]="{'flex justify-between': enableIconLabel}">
    <span>
      {{ label }}
    </span>
    <span *ngIf="enableIconLabel">
      <i [class]="iconLabel+' hover:cursor-pointer hover:opacity-70'" (click)="emitClickIconLabel()"></i>
    </span>
  </label>
  <input
    *ngIf="model"
    [style]="style"
    [type]="type"
    [name]="name"
    [id]="inputId"
    [tabindex]="tabIndex"
    [(ngModel)]="model"
    (ngModelChange)="changeModel()"
    [thousandSeparator]="thousandSeparator"
    [mask]="mask"
    [min]="min"
    [prefix]="prefix"
    decimalMarker=","
    [class]="inputClass"
    [placeholder]="placeholder"
    [required]="required"
    [isDisabled]="isDisabled"
  />
  <input
    *ngIf="!model"
    [style]="style"
    [type]="type"
    [name]="name"
    [id]="inputId"
    [tabindex]="tabIndex"
    [formControl]="control"
    [thousandSeparator]="thousandSeparator"
    [mask]="mask"
    [min]="min"
    [prefix]="prefix"
    (keydown)="blockCharacters($event)"
    decimalMarker=","
    [ngClass]="{
              '!border-rose-500': control.dirty && control.invalid,
              '!border-blue-500': control.dirty && !control.invalid,
              '!bg-gray-200': isDisabled
            }"
    [class]="inputClass"
    [placeholder]="placeholder"
    [required]="required"
    [isDisabled]="isDisabled"
  />
  <i
    class="absolute top-[1rem] right-2.5 text-sm text-gray-500"
    *ngIf="type === 'password' || (type === 'text' && showPassword === true)"
    [ngClass]="{ 'fal fa-eye': !showPassword, 'fa fa-eye-slash': showPassword }"
    (click)="toggleShowPassword()"
  ></i>
</div>