import { Component } from '@angular/core';

@Component({
  selector: 'app-simulate-and-compare',
  templateUrl: './simulate-and-compare.component.html',
  styleUrl: './simulate-and-compare.component.scss'
})
export class SimulateAndCompareComponent {

  
}
