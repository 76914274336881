import { Component } from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MathService } from './dashboard/calculator/services/math.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isProduction= environment.production;

  cookieConsentAccepted = localStorage.getItem('cookieConsentAccepted');

  constructor(private mathService: MathService,private router: Router){
    const r = this.mathService.nper(7, 1150,0, 100000);
    const x = this.mathService.pgto(7, 120,100000, 0,0);

    const browserKey = localStorage.getItem('browserKey');
    if(!browserKey)
    localStorage.setItem('browserKey', Date.now()+'');

    document.documentElement.classList.remove('dark');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => window.scrollTo(0, 0));
  }

  acceptCookieConsent(){
    localStorage.setItem('cookieConsentAccepted', 'true');
    this.cookieConsentAccepted = 'true';
  }
}
