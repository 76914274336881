import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-title',
  template: `<div class=" mt-4 mb-10 pb-1 md:px-0 px-6">
  <p class="text-3xl font-bold border-b text-gray-700 pb-3" [ngClass]="{'text-center': centered}">{{title}}</p>
    <p *ngIf="subtitle" class="text-sm text-gray-500"><span [innerHTML]="subtitle"></span></p>
  </div>`
})
export class PageTitleComponent {
  @Input() title: string = '';
  @Input() subtitle?: string;
  @Input() centered?: boolean = false;
}
