<div class="flex flex-column md:px-0 px-4">
  <div class="w-full">
    <div class="m-1 xl:m-2">
      <p class="my-2 font-bold text-lg md:text-2xl">
        Fale conosco
      </p>
      <p class="text-sm">
        Envie suas dúvidas e sugestões e entraremos em contato o mais breve possível.
      </p>
    </div>
  </div>
  <div class="grid grid-cols-2 w-full">
    <div class="col-span-2 md:col-span-1 m-1 xl:m-2">
      <label for="nome" class="block mb-2 text-sm font-bold" 
        [ngClass]="{'text-slate-100': color === 'white', 'text-black': color === 'gray'}">
        Nome *
      </label>
      <input tabindex="1" required [(ngModel)]="contactFormData.firstName" type="text" id="nome" 
        class="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        [ngClass]="{ 'bg-blue-500 border-blue-200 text-white': color === 'white', 'bg-gray-50 border-gray-300': color === 'gray'}"
      />
    </div>
    <div class="col-span-2 md:col-span-1 m-1 xl:m-2">
      <label for="telefone" class="block mb-2 text-sm font-bold" 
        [ngClass]="{ 'text-slate-100': color === 'white', 'text-black': color === 'gray'}">
        Telefone *
      </label>
      <input tabindex="2" required [(ngModel)]="contactFormData.phoneNumber" type="text" id="telefone" 
        class="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        [ngClass]="{ 'bg-blue-500 border-blue-200 text-white': color === 'white', 'bg-gray-50 border-gray-300': color === 'gray'}"
      />
    </div>
  </div>
  <div class="w-full">
    <div class="m-1 xl:m-2">
      <label for="email" class="block mb-2 text-sm font-bold" [ngClass]="{ 'text-slate-100': color === 'white', 'text-black': color === 'gray'}">
        Email *
      </label>
      <input tabindex="3" required [(ngModel)]="contactFormData.email" type="text" id="email" 
        class="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
        [ngClass]="{ 'bg-blue-500 border-blue-200 text-white': color === 'white', 'bg-gray-50 border-gray-300': color === 'gray'}"
       />
    </div>
  </div>
  <div class="w-full">
    <div class="m-1 xl:m-2">
      <label for="mensagem" class="block mb-2 text-sm font-normal" 
        [ngClass]="{ 'text-slate-100': color === 'white', 'text-black': color === 'gray'}">
        Mensagem
      </label>
      <textarea id="mensagem" tabindex="4" [(ngModel)]="contactFormData.message" rows="6"
        class="w-full bg-white bg-opacity-20 border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 resize-none"
        [ngClass]="{ 'bg-blue-500 border-blue-200 text-white': color === 'white', 'bg-gray-50 border-gray-300': color === 'gray'}"></textarea>
    </div>
  </div>
  <div class="w-full">
    <div class="flex m-1 xl:m-2">
      <input id="termos" required tabindex="5" type="checkbox" value="" [(ngModel)]="contactFormData.termAccepted" 
        class="border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 self-start md:self-center"
      />
      <label for="termos" class="ms-2 text-[11px] text-justify"
        [ngClass]="{ 'text-slate-100': color === 'white', 'text-black': color === 'gray'}">
        Ao enviar este formulário confirmo que estou de acordo com os Temos e condições e Política de privacidade da Planeta Invest
      </label>
    </div>
  </div>
  <div class="flex justify-end pt-3 md:py-1">
      <app-button tabindex="6" theme="secondary" [isDisabled]="!contactFormData.termAccepted" (click)="sendContactForm()"
        [buttonClass]="'h-10 py-2 px-6 font-light tracking-wide transition-colors duration-300 transform rounded-lg focus:outline-none focus:ring-opacity-80 focus:ring block'">
        Enviar mensagem
      </app-button>
  </div>
</div>
