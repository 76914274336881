<div
  class="flex w-full flex-col md:flex-row"
  id="encontre-investimento"
  #targetFindInvestments
>
  <div class="flex md:w-1/2 lg:pl-20 lg:grow lg:w-1/3 lg:max-w-7xl">
    <div
      class="w-full p-6 lg:p-0 content-center space-y-3 my-8 md:my-16 lg:my-0"
    >
      <h1 class="font-extrabold text-3xl text-gray-600">
        Encontre o seu investimento
      </h1>
      <p class="font-normal text-lg text-gray-500 text-[16px] lg:text-base">
        Explore os investimentos disponíveis e compare as melhores opções para você
      </p>
      <a
        class="text-blue-600 font-medium place-self-start text-[16px] lg:text-base"
        style="font-weight: 600; margin-top: 35px"
        [routerLink]="['/investments']"
      >
        Ver todos <u>os ativos</u>&nbsp;<i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div>
  <div>
    <img src="/assets/images/container.svg" />
  </div>
</div>
