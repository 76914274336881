
<div class="fixed w-0 z-20" [ngClass]="{'w-full': isOpen}">
  <nav class="flex flex-col fixed left-0 w-0 h-screen bg-white overflow-y-auto shadow-my-light z-10 nav-mobile" [ngClass]="{'w-[250px]': isOpen}">
    <ul class="mb-14 h-fit">
      <li
        *ngFor="let item of items"
        [routerLink]="item.path"
        (click)="toggleMenu()"
        class="border-b-2 border-slate-100 p-4 whitespace-nowrap"
      >
        {{ item.title }}
      </li>
      <li class="border-b-2 border-slate-100 p-4 whitespace-nowrap" (click)="toggleMenu()">
        <a [href]="blogLink" target="_blank">Blog</a>
      </li>
    </ul>
    <ul class="w-full h-full bg-primary-pure p-4">
      <li *ngFor="let link of links" class="text-white p-2 whitespace-nowrap" (click)="toggleMenu()">
        <a [routerLink]="[link.path]">
          <span class="block font-semibold">{{link.title}}</span>
          <span class="block text-sm">{{link.description}}</span>
        </a>
      </li>
      <li class="max-w-max py-2 px-4 bg-white text-blue-500 rounded-lg mt-4 ml-2 whitespace-nowrap" (click)="scrollBottom()">Entrar em contato</li>
    </ul>
  </nav>
  <div class="bg-slate-400 h-screen fixed right-0 transition-opacity" [ngClass]="{'opacity-30 w-full': isOpen, 'w-0 opacity-0': !isOpen}" (click)="toggleMenu()">
  </div>
</div>

