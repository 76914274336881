import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MobileMenuItem } from './mobile-menu-item.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss'
})

export class MobileMenuComponent {

  @Input() items: Array<MobileMenuItem> = []
  @Input() links: Array<MobileMenuItem> = []
  @Input() isOpen:boolean = false;

  @Output() openClose:EventEmitter<boolean> = new EventEmitter<boolean>(false);

  blogLink = 'http://planetainvest.blog'

  constructor(
    private router:Router
  ){
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
    this.openClose.next(this.isOpen);
  }


  scrollBottom(){
    window.scrollTo(0, document.body.scrollHeight);
  }


  get routerUrl(){
    return this.router.url;
  }
}
