import { Component } from '@angular/core';

@Component({
  selector: 'app-thought-for-you',
  templateUrl: './thought-for-you.component.html',
  styleUrl: './thought-for-you.component.scss'
})
export class ThoughtForYouComponent {

}
