<section class="grid grid-cols-2 px-6 py-12 lg:px-20 gap-16">
  <div class="col-span-2 md:col-span-1">
    <img src="../../../../../assets/images/laptop.svg" class="w-full" alt="" />
  </div>
  <div class="col-span-2 md:col-span-1 flex flex-col gap-4 justify-center">
    <span class="font-extrabold text-4xl text-gray-600"> Simule e compare as melhores opções </span>
    <span class="text-blue-600 text-4xl">
      Vamos descobrir qual é o caminho mais inteligente e econômico para você?
    </span>
    <span class="text-xl text-gray-500">
      Deixe todo o esforço com a gente e economize tempo de pesquisa: nós comparamos todos os produtos financeiros para você.
    </span>
    <app-button [routerLink]="['/investments']" theme="primary" buttonWidth="fit">Simular agora</app-button>
  </div>
</section>
