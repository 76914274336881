<section class="grid grid-cols-2 px-6 py-12 gap-14 bg-gradient-to-r from-blue-700 to-indigo-300 lg:px-20 lg:py-24">
  <div class="col-span-2 md:col-span-1 flex items-center">
    <div class="flex flex-col">
      <img src="../../../../../assets/images/open-finance.svg" class="mb-16" alt="" />
      <span class="text-4xl text-white mb-6"> Transparência do Open Finance </span>
      <span class="text-xl text-white">
        Buscamos na internet todas as opções de produtos e instituições financeiras para você alcançar os seus objetivos com as melhores vantagens.
      </span>
    </div>
  </div>
  <div class="col-span-2 md:col-span-1 flex justify-center items-center">
    <img src="../../../../../assets/images/bank2.svg" class="w-full" alt="">
  </div>
</section>
