import { Component } from '@angular/core';

@Component({
  selector: 'app-academy-section',
  templateUrl: './academy-section.component.html',
  styleUrl: './academy-section.component.scss'
})
export class AcademySectionComponent {

}
