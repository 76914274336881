<footer class="bg-blue-600 text-white ">
  <div class="flex flex-col md:grid md:grid-cols-2 container lg:p-0">
    <div class="py-4">
      <app-contact-form [color]="'white'" [columns]="'3'"></app-contact-form>
    </div>
    <div class="py-4">
      <div class="grid grid-cols-3 gap-3 md:py-8 py-3 justify-items-center sm:justify-items-end my-2 lg:px-3 px-1">
        <div>
          <p class="text-sm md:text-lg font-semibold mb-5">
            Empresa
          </p>
          <div class="flex flex-col justify-start">
            <a [routerLink]="['/quem-somos']" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Quem somos
            </a>
            <a href="mailto:contato@planetainvest.com.br" target="_blank" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Entre em contato
            </a>
          </div>
        </div>
        <div>
          <p class="text-sm md:text-lg font-semibold mb-5">
            Atendimento ao cliente
          </p>
          <div class="flex flex-col justify-start">
            <a href="" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Glossário
            </a>
            <a [routerLink]="['/faq']" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              FAQ
            </a>
            <a [routerLink]="['/termos-de-uso']" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Termos de uso
            </a>
            <a [routerLink]="['/politica-de-privacidade']" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Política de privacidade
            </a>
          </div>
        </div>
        <div>        
          <p class="text-sm md:text-lg font-semibold mb-5">
            Explore mais
          </p>
          <div class="flex flex-col justify-start">
            <a [routerLink]="['/academy']" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Planeta academy
            </a>
            <a href="https://planetainvest.blog/" target="_blank" class="text-xs md:text-base font-semibold hover:cursor-pointer hover:opacity-70 my-1" style="text-decoration:none;">
              Blog
            </a>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-3 md:py-8 justify-items-center sm:justify-items-end my-4 md:my-0 lg:px-3 px-6">
        <div class="flex flex-col items-center sm:items-start py-2 md:py-8">
          <div class="my-1">
            <img
              class="max-h-6"
              src="assets/images/planeta-invest-horizontal-white.svg"
              alt=""
            />
          </div>
          <p class="text-sm font-bold mt-1 mb-2">contato&#64;planetainvest.com.br</p>
          <div class="flex flex-row items-center text-[2em] my-1 gap-3">
            <a href="https://www.facebook.com/planetainvestoficial" target="_blank" class="hover:opacity-70">
              <i class="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/planetainvestoficial" target="_blank" class="hover:opacity-70">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/planeta-invest/" target="_blank" class="hover:opacity-70">
              <i class="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div class="d-flex p-5 flex-column align-items-center align-items-lg-start">
          <a
            href="https://transparencyreport.google.com/safe-browsing/search?url=https://www.planetainvest.com.br"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img src="assets/images/google-safe-browsing.png" width="216" alt="Google Safe Browsing" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-gray-800">
    <div class="py-3 text-white font-normal text-base text-center">
      ©{{ getYear() }} Planeta Invest. Todos os direitos reservados
    </div>
  </div>
</footer>
