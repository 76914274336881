<div class="modal-primary" [ngClass]="size" @modal *ngIf="showModal">
    <div class="modal-primary-header flex justify-between border-slate-100 py-3">
        <h3 class="font-semibold text-4xl flex items-center" [ngClass]="titleClass">
            <span *ngIf="titleIcon">
                <i [ngClass]="titleIcon"></i>
            </span>
            <span *ngIf="title">
                {{title}}
            </span>
        </h3>
        <a (click)="closeModal()" 
            class="text-white hover:cursor-pointer hover:opacity-100 opacity-50 text-3xl transition-colors rounded-full bg-red-500 w-10 h-10 shadow-md flex justify-center items-center" style="text-decoration: none;">
            <i class="fal fa-times"></i>
        </a>
    </div>
    <div class="modal-primary-content">
        <div #modalPrimaryContent >
    
        </div>
    </div>
</div>
<div class="modal-primary-overlay" @modalOverlay (click)="closeModal()" *ngIf="showModal">

</div>