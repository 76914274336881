import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { InstancesChanges, NgxTippyInstance, NgxTippyService } from 'ngx-tippy-wrapper';
import { Subscription } from 'rxjs';
import { PercentIndex } from 'src/app/_dtos/indexes.dto';
import { TaxesService } from 'src/app/_services/taxes.service';

@Component({
  selector: 'app-indexers',
  standalone: false,
  templateUrl: './indexers.component.html',
  styleUrl: './indexers.component.scss',
})
export class IndexersComponent implements OnInit, AfterViewInit, OnDestroy {
  
  firestore: Firestore = inject(Firestore);
  indexes: PercentIndex[];;
  docRef = doc(this.firestore, 'data', 'configs');
  now: Date = new Date();
  public marqueeAtivado:boolean=true;
  private instancesChanges$: Subscription;

  constructor(
    private http: HttpClient,
    private taxesService: TaxesService,
    private tippyService:NgxTippyService
    ) {
    }

  async ngOnInit(): Promise<void> {

    await this.getData();
  }

  async getData() {
    const data = await getDoc(this.docRef);
    this.indexes = data.data()!['indexes'];

    const dt = await this.taxesService.getTaxes().toPromise();
    this.indexes = this.indexes.map((item:any) => {
      if (dt.hasOwnProperty(item.key)) {
        return {
          ...item,
          value: dt[item.key].toString() // Atualiza o valor e converte para string
        };
      }
      return item;
    });
    await setDoc(this.docRef, {indexes: this.indexes}, {merge: true});

  }

  ngAfterViewInit(): void {
    this.instancesChanges$ = this.tippyService.instancesChanges.subscribe((changes:InstancesChanges)=>{
        this.tippyService.getInstances()?.forEach((instance:NgxTippyInstance)=>{
          instance.props.onHide = ()=>{
            (window.document.getElementById('marquee-indexers') as any)?.start();
            this.marqueeAtivado=true;
          }
        });
    });

    setTimeout(()=>{
      this.instancesChanges$ && this.instancesChanges$.unsubscribe();
    },3000)
  }

  ngOnDestroy(): void {
    this.instancesChanges$ && this.instancesChanges$.unsubscribe();
  }

  gerenciarMarqueeTooltip(tooltipId:string){
    if(this.marqueeAtivado){
      this.tippyService.show(tooltipId);
      (window.document.getElementById('marquee-indexers') as any)?.stop();
      this.marqueeAtivado=false;
    }else{
      (window.document.getElementById('marquee-indexers') as any)?.start();
      this.marqueeAtivado=true;
    }
  }

}
