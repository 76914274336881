import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../_services/auth.service";
import { environment } from "src/environments/environment";
import { catchError, Observable } from "rxjs";
import { Router } from "@angular/router";
import { AlertService } from "../_services/alert.service";


enum ApiTypeEnum{
    API_PLANETA='API_PLANETA',
  }

@Injectable({
    providedIn: 'root'
  })
export class RequestInterceptor implements HttpInterceptor {
    
    private token:string='';
    private apiReqType:ApiTypeEnum;
    private errorCodes:Map<number,string>;

    constructor(
        private authService:AuthService,
        private router:Router,
        private alertService:AlertService
    ){
        this.token = this.authService.getCurrentToken();
        this.authService.authenticated.subscribe(res=>{
            if(res){
               let tokenStorage = this.authService.getCurrentToken();
               if(tokenStorage!=null){
                this.token = tokenStorage;
               }
            }
        });

        this.errorCodes = new Map();
        this.errorCodes.set(400,'Requisição com formato inválido');
        this.errorCodes.set(401,'Sessão expirada ou inválida');
        this.errorCodes.set(403,'Você não possui permissão');
        this.errorCodes.set(500,'Erro no servidor');
        this.errorCodes.set(502,'Erro no servidor');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.verificarApiUrl(req.url);
        if(this.apiReqType==ApiTypeEnum.API_PLANETA){
            return this.handleApiPlanetaToken(req, next).pipe(catchError((error:HttpErrorResponse)=>{
              var knowErrorMessage =  this.errorCodes.get(error.status);
              if(error.status==401){
                this.alertService.showError(knowErrorMessage || 'Sessão expirada ou inválida');
                setTimeout(()=>{
                  this.authService.logout();
                  this.router.navigateByUrl('/');
                },2000);
              }
              throw error;
            }));
        }
        return next.handle(req);
    }

    verificarApiUrl(url:string):boolean{
        let serverUrl = url.split('/');
        var serverAddr = serverUrl[2];
        if(serverAddr && serverAddr?.length>0){
          if(serverAddr == environment.apiPlanetaDomain){
            this.apiReqType = ApiTypeEnum.API_PLANETA;
            return true;
          }
        }
        return false;
      }

      handleApiPlanetaToken(req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>>{
        return next.handle(req.clone({
            setHeaders:{
              'Content-Type':'application/json',
              'Authorization': `Bearer ${this.token}`
            }
          }));
      }
}