<div
  class="app-splash-screen"
  [ngStyle]="{ 
    left: windowWidth, 
    opacity: opacityChange,
    transition: splashTransition
  }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <i class="fal fa-2x fa-spin text-white mt-5 fa-circle-notch"></i>
    <div style="display: none;" class="app-loader"></div>
  </div>
</div>