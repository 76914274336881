import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  navigateToContact: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  getNavigateToContact(): Observable<boolean> {
    return this.navigateToContact.asObservable();
  }

  setNavigateToContact(value: boolean) {
    this.navigateToContact.next(value);
  }

  formatTime(timeInMonths: number): string {
    if (timeInMonths === 0) {
      return '';
    }
  
    const years = Math.floor(timeInMonths / 12);
    const months = timeInMonths % 12;
  
    let formattedTime = '';
  
    if (years !== 0) {
      formattedTime += years === 1 ? `${years} ano` : `${years} anos`;
    }
  
    if (months !== 0) {
      if (formattedTime.length > 0) {
        formattedTime += ' ';
      }
      formattedTime += months === 1 ? `${months} mês` : `${months} meses`;
    }
  
    return formattedTime;
  }

}