<section class="grid grid-cols-2 py-8 lg:py-[10.25rem] gap-8 lg:gap-16 px-6 lg:px-20">
  <div class="col-span-2 md:col-span-1">
    <img src="../../../../../assets/images/video2.png" alt="">
  </div>
  <div class="col-span-2 md:col-span-1 flex flex-col gap-4">
    <img
      src="../../../../../assets/images/planeta-academy-horizontal.svg"
      class="w-full md:w-1/2"
      alt=""
    />
    <span class="text-4xl md:text-5xl text-gray-900 font-extrabold">Aprenda a investir</span>
    <span class="text-gray-500 text-xl">
      Assuma o controle da sua vida financeira de uma vez por todas, aprendendo aqui com os nossos cursos e certificações.
    </span>
    <app-button buttonWidth="fit" icon="fas fa-arrow-right" iconPosition="right">Ver cursos</app-button>
  </div>
</section>
