import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './_guards/auth.guard';


const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./acesso/acesso.module').then(m => m.AcessoModule)
  },  
  { path: 'sistema', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'academy', redirectTo: 'academy', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'academy',
    loadChildren: () => import('./acedemy/acedemy.module').then(m => m.AcedemyModule)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
