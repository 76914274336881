import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

interface SelectOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
})
export class SelectComponent implements OnInit, Validator {
  @Input() items: SelectOption[] = [];
  @Input() control!: FormControl;
  @Input() isMultiple = false;

  @Input() id = '';
  @Input() bindLabel = '';
  @Input() bindValue = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() selectClass = 'w-full rounded-xl text-sm';
  @Input() clearable = false;
  @Input() label = '';
  @Input() labelClass = 'block text-[12px] font-medium pb-2';
  @Input() disableLabel:boolean = false;
  @Input() enableIconLabel:boolean = false;
  @Input() iconLabel:string = 'fal fa-info-circle';

  @Output() iconLabelClicked: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.control) {
      throw new Error('FormControl is required for SelectComponent');
    }
  }

  writeValue(obj: any): void {
    this.control.markAsTouched();
    this.control.setValue(obj);
  }


  validate(control: FormControl) {
    return this.control.valid ? null : { invalid: true };
  }

  emitClickIconLabel(){
    this.iconLabelClicked.next('');
  }
}
