<section class="h-auto px-6 py-20 lg:h-2/3 lg:px-20 lg:py-40">
  <div class="grid grid-cols-3 gap-16 items-center">
    <div class="col-span-3 md:col-span-1 flex flex-col gap-4">
      <span class="text-4xl text-gray-600">Tecnologia pensada para você</span>
      <span class="text-xl text-gray-500">
        Uma plataforma fácil e intuitiva que te ajuda a tomar as melhores decisões sobre os seus investimentos.
      </span>
      <a [routerLink]="" class="text-lg text-blue-600 hover:text-blue-500"
        >Ver mais<i class="fas fa-chevron-right ml-2"></i
      ></a>
    </div>
    <div class="col-span-3 md:col-span-1 flex flex-col gap-4">
      <div class="flex flex-col gap-4">
        <div
          class="w-12 h-12 p-3 rounded-xl bg-slate-100 text-blue-600 flex items-center justify-center"
        >
          <img src="../../../../../assets/icons/team.svg" alt="" />
        </div>
        <span class="font-bold text-xl text-gray-900">Democratizamos a forma de investir</span>
        <span class="text-gray-500">
          Unimos todas as pessoas em um só lugar, pois investir é para todos.
        </span>
      </div>
      <div class="flex flex-col gap-4">
        <div
          class="w-12 h-12 p-3 rounded-xl bg-slate-100 text-blue-600 flex items-center justify-center"
        >
          <img src="../../../../../assets/icons/invention.svg" alt="" />
        </div>
        <span class="font-bold text-xl text-gray-900">Fácil de usar</span>
        <span class="text-gray-500">
          Simples de entender, prático de simular e não precisa ser especialista.
        </span>
      </div>
    </div>
    <div class="col-span-3 md:col-span-1 flex flex-col gap-4">
      <div class="flex flex-col gap-4">
        <div
          class="w-12 h-12 p-3 rounded-xl bg-slate-100 text-blue-600 flex items-center justify-center"
        >
          <img src="../../../../../assets/icons/consulting.svg" alt="" />
        </div>
        <span class="font-bold text-xl text-gray-900">Transparência para você</span>
        <span class="text-gray-500">
          Com a inteligência e a segurança de uma plataforma independente
        </span>
      </div>
      <div class="flex flex-col gap-4">
        <div
          class="w-12 h-12 p-3 rounded-xl bg-slate-100 text-blue-600 flex items-center justify-center"
        >
          <img src="../../../../../assets/icons/target-alt-1.svg" alt="" />
        </div>
        <span class="font-bold text-xl text-gray-900">Autonomia financeira</span>
        <span class="text-gray-500">
          Tome a melhor decisão com uma visão comparativa completa dos produtos financeiros disponíveis no mercado.
        </span>
      </div>
    </div>
  </div>
</section>
