<section class="h-auto pb-8">
  <div class="grid grid-cols-2">
    <div class="col-span-2 lg:col-span-1 flex flex-col justify-center gap-6 p-6 lg:px-20">
      <h1 class="text-gray-600 font-bold text-[40px] md:text-5xl">
        A trajetória para
        <span class="block">concretizar seus <br />
          sonhos começa aqui</span>
      </h1>
      <h4 class="font-normal text-blue-500 leading-8 text-2xl">
        Compare diversos produtos financeiros em um só lugar
      </h4>
      <p class="font-normal text-gray-500 text-justify">
        Facilitamos sua escolha sobre qual o melhor caminho para alcançar seus objetivos, conectamos você com as
        melhores instituições, transformando a sua tomada de decisão em uma jornada simples, transparente e
        democrática.
      </p>
      <p class="font-normal text-gray-500 text-justify mb-0">
        Selecione um sonho para você conquistar
      </p>
      <app-button type="button" buttonWidth="200px" [isLoading]="isLoading" (click)="onButtonClick()">Começar</app-button>
    </div>
    <div class="col-span-2 lg:col-span-1">
      <div class="grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:grid-rows-2 gap-0 md:gap-4 mt-8 md:mt-0">
        <div class="bg-blue-800 overflow-hidden">
          <div
            class="cover aspect-video md:aspect-square card01"
            (mouseover)="buttons.card01.hovered = true"
            (mouseleave)="buttons.card01.hovered = false">
            <div
              class="card-content w-100 animate__animated"
              [ngClass]="{ animate__slideInUp: buttons.card01.hovered }">
              <h4 class="text-white text-left">Sonho da casa nova</h4>
              <button
                type="button"
                *ngIf="buttons.card01.hovered"
                (click)="onButtonClick()"
                class="animate__animated animate__fadeIn text-blue-500 bg-white focus:outline-none hover:bg-blue-500 hover:text-gray-100 focus:ring-1 focus:ring-red-100 font-medium rounded-lg text-xs px-3 py-3 me-2 mb-2 mt-4 transition duration-700">
                Concretizar sonho
              </button>
            </div>
          </div>
        </div>
        <div class="bg-blue-800 overflow-hidden">
          <div
            class="cover aspect-video md:aspect-square card02"
            (mouseover)="buttons.card02.hovered = true"
            (mouseleave)="buttons.card02.hovered = false">
            <div
              class="card-content w-100 animate__animated"
              [ngClass]="{ animate__slideInUp: buttons.card02.hovered }">
              <h4 class="text-white text-left">Sonho da viagem incrível</h4>
              <button
                type="button"
                *ngIf="buttons.card02.hovered"
                class="animate__animated animate__fadeIn text-blue-500 bg-white focus:outline-none hover:bg-blue-500 hover:text-gray-100 focus:ring-1 focus:ring-red-100 font-medium rounded-lg text-xs px-3 py-3 me-2 mb-2 mt-4 transition duration-700">
                Em breve
              </button>
            </div>
          </div>
        </div>
        <div class="bg-blue-800 overflow-hidden">
          <div
            class="cover aspect-video md:aspect-square card03"
            (mouseover)="buttons.card03.hovered = true"
            (mouseleave)="buttons.card03.hovered = false">
            <div
              class="card-content w-100 animate__animated"
              [ngClass]="{ animate__slideInUp: buttons.card03.hovered }">
              <h4 class="text-white text-left">Sonho do primeiro milhão</h4>
              <button
                type="button"
                *ngIf="buttons.card03.hovered"
                class="animate__animated animate__fadeIn text-blue-500 bg-white focus:outline-none hover:bg-blue-500 hover:text-gray-100 focus:ring-1 focus:ring-red-100 font-medium rounded-lg text-xs px-3 py-3 me-2 mb-2 mt-4 transition duration-700">
                Em breve
              </button>
            </div>
          </div>
        </div>
        <div class="bg-blue-800 overflow-hidden">
          <div
            class="cover aspect-video md:aspect-square card04"
            (mouseover)="buttons.card04.hovered = true"
            (mouseleave)="buttons.card04.hovered = false">
            <div
              class="card-content w-100 animate__animated"
              [ngClass]="{ animate__slideInUp: buttons.card04.hovered }">
              <h4 class="text-white text-left">Sonho do novo carro</h4>
              <button
                type="button"
                *ngIf="buttons.card04.hovered"
                class="animate__animated animate__fadeIn text-blue-500 bg-white focus:outline-none hover:bg-blue-500 hover:text-gray-100 focus:ring-1 focus:ring-red-100 font-medium rounded-lg text-xs px-3 py-3 me-2 mb-2 mt-4 transition duration-700">
                Em breve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
