import { Component } from '@angular/core';

@Component({
  selector: 'app-open-finance-transparency',
  templateUrl: './open-finance-transparency.component.html',
  styleUrl: './open-finance-transparency.component.scss'
})
export class OpenFinanceTransparencyComponent {

}
