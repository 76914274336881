  <header class="flex bg-slate-100 items-center sticky top-0 z-20 border-b border-violet-50 shadow-sm py-4 md:py-1">
    <div class="px-2 md:px-4 flex items-center">
      <div class="lg:hidden">
        <div class="flex w-10 mx-3" (click)="mobileMenuOpened = !mobileMenuOpened" *ngIf="menuItems.length">
          <img src="assets\icons\icon-menu.svg" *ngIf="!mobileMenuOpened" alt="" class="icon-menu animate__animated animate__fadeIn" />
          <img src="assets\icons\icon-close.svg" *ngIf="mobileMenuOpened"  alt="" class="icon-menu animate__animated animate__fadeIn" />
        </div>
      </div>
      <div class="flex w-auto items-center h-fit">
        <a href="/public" class="w-14">
          <img src="assets/images/blue-p.svg" alt="">
        </a>
      </div>
      <div *ngIf="isBackEnabled" class="items-center hidden">
        <a
          (click)="back()"
          class="hover:cursor-pointer no-underline font-bold flex items-center hover:opacity-70 md:mr-3 mr-6" style="text-decoration: none;">
            <div class="mr-2 md:text-lg text-xl">
              <i class="fal fa-chevron-left"></i>
            </div>
            <p class="hidden lg:block">Voltar</p>
        </a>
      </div>
    </div>
    <nav id="nav" class="hidden lg:flex container">
      <div class="flex justify-between items-center">
        <div class="flex w-auto py-1" *ngFor="let item of menuItems">
          <a
            [routerLink]="[item.path]"
            class="flex flex-col items-center text-slate-500 text-sm font-medium p-2 px-4 cursor-pointer !no-underline">
            <p routerLinkActive="!border-blue-600 text-blue-600" [routerLinkActiveOptions]="{exact:true}"
              class="border-b-2 border-slate-100 hover:border-blue-600 hover:text-blue-600 h-max py-2">
              {{item.title}}
            </p>
          </a>
        </div>
        <div class="flex w-auto py-1" >
          <a href="//planetainvest.blog" target="_blank" 
            class="flex flex-col items-center text-slate-500 text-sm font-medium p-2 px-4 cursor-pointer !no-underline">
            <p [ngClass]="{'!border-blue-600 text-blue-600' : routeUrl=='/blog'}"
              class="border-b-2 border-slate-100 hover:border-blue-600 hover:text-blue-600 h-max py-2">
              Blog
            </p>
          </a>
        </div>
      </div>
    </nav>
    <div class="flex justify-around items-center px-2 hidden">
      <div *ngIf="usuario">
        <a class="mr-1 text-gray-500 border-r pr-2 lg:pr-5 hover:cursor-pointer no-underline hover:opacity-70">
          <i class="fas fa-bell"></i>
        </a>
        <div  class="ml-2 lg:ml-5">
          
        </div>
      </div>
      <div *ngIf="!usuario" class="flex justify-around items-center">
        <a class="flex items-center no-underline text-slate-500 text-sm font-medium p-4 hover:cursor-pointer hover:opacity-70" style="text-decoration: none;">
          <span class="mr-1">Cadastrar</span>&nbsp;
          <i class="fas fa-user-plus"></i>
        </a>
        <button class="ml-2 lg:ml-5 hover:cursor-pointer hover:opacity-70 flex items-center h-10 w-fit px-1 bg-white text-slate-500 text-base border border-slate-200 gap-1 rounded-3xl shadow">
          <div class="bg-slate-50 rounded-full ml-1 mr-2 hidden lg:flex h-6 w-6 items-center justify-center">
            <img src="assets\icons\icon-user.svg" alt="">
          </div>
          <span class="text-sm ml-1">Entrar</span>&nbsp;
          <i class="fas fa-sign-in-alt pr-2"></i>
        </button>
      </div>
    </div>
  </header>
  <div class="lg:hidden">
    <app-mobile-menu [items]="menuItems" [links]="mobileMenuLinks" [isOpen]="mobileMenuOpened" (openClose)="mobileMenuOpened=$event"></app-mobile-menu>
  </div>
  