<aside class="bg-slate-300 py-6 pr-6 h-full">
  <div class="bg-slate-700 text-slate-700 banner h-full">
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    
  </div>
</aside>


<!-- before refactory
  <aside class="bg-gradient-to-r from-purple-900 from-5% to-blue-700 to-60% ... fixed w-full section-full">
  <div class="bg-no-repeat ring-blue w-full">
    <div class="w-full h-full bg-no-repeat logo">
    </div>
  </div>
</aside>
 -->