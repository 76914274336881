<nav style="margin-left: 1px;">
  <div id="nav"
    class="flex bg-white lg:bg-slate-100  border-b-red-500 h-20 items-center shadow-0 lg:shadow-my-light px-6 lg:px-12 ">
    <div class="container flex">
    <div class="flex items-center justify-between w-full lg:hidden">
      <div class="w-auto" routerLink="/" class="cursor-pointer">
        <img src="assets\images\blue-p.svg">
      </div>
      <app-mobile-menu [items]="menuItems" [links]="mobileMenuLinks"></app-mobile-menu>
    </div>
    <div class="flex hidden lg:flex justify-between">
      <div class="flex w-auto py-4" *ngFor="let item of menuItems">
        <a
          [routerLink]="[item.path]"
          class="flex flex-col items-center text-slate-500 text-sm font-medium p-4 cursor-pointer !no-underline">
          <p routerLinkActive="!border-blue-600 text-blue-600" [routerLinkActiveOptions]="{exact:true}"
            class="border-b-2 border-slate-100 hover:border-blue-600 hover:text-blue-600 h-max py-4">
            {{item.title}}
          </p>
        </a>
      </div>
      <div class="flex w-auto py-4 " >
        <a href="//planetainvest.blog" target="_blank"
          class="flex flex-col items-center text-slate-500 text-sm font-medium p-4 cursor-pointer !no-underline">
          <p class="hover:border-b-2 hover:border-blue-600 hover:text-blue-600  h-max py-4">Blog</p>
        </a>
      </div>
    </div>
    <div class="flex justify-between items-center hidden">
      <a
        class="flex flex-col items-center text-slate-500 text-sm font-medium p-4 cursor-pointer">
        <p>Cadastrar</p>
      </a>
      <button
        class="flex items-center h-10 w-32 px-1 bg-white text-slate-700 text-base border border-slate-200 gap-1 rounded-3xl shadow">
        <div class="bg-slate-50 rounded-full ml-1 mr-2 flex h-6 w-6 items-center justify-center">
          <img src="assets\icons\icon-user.svg" alt="">
        </div>
        Entrar
        <div class="ml-2">
          <img src="assets\icons\icon-arrow-down.svg" alt="" class="h-2">
        </div>
      </button>
    </div>
  </div>
</div>

</nav>
