import { Component, Input } from '@angular/core';
import { AcademyCard } from 'src/app/acedemy/academy-home/academy-home.component';

@Component({
  selector: 'app-academy-favorite-card',
  templateUrl: './academy-favorite-card.component.html',
  styleUrl: './academy-favorite-card.component.scss'
})
export class AcademyFavoriteCardComponent {
  @Input() card!: AcademyCard;
}
