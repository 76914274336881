<div class="grid  grid-cols-1 bg-slate-900 text-xs font-extrabold text-white p-2" *ngIf="indexes">
  <div class="flex container justify-center">
    <span class="text-xs font-extrabold leading-5 mr-2">INDEXADORES:</span>
    <marquee id="marquee-indexers" class="inline-flex md:hidden items-center" scrolldelay="150">
      <div class="inline-flex items-center" *ngFor="let index of indexes; let i = index" (click)="gerenciarMarqueeTooltip('tooltip-sm-'+i)">
        <span class="text-xs font-extrabold leading-5 mr-1">{{index.label}}:</span>
        <span class="text-xs font-normal leading-5 mr-1">{{index.value}}%&nbsp;{{index.periodo}}</span>
        <i 
          class="tooltip-sm"
          ngxTippy="{{index.description}}" 
          tippyName="tooltip-sm-{{i}}" 
          alt="" 
          class="fal text-blue-600 fa-info-circle"
        ></i>
        &nbsp;
        &nbsp;
        &nbsp;
      </div>
    </marquee>
    <span class="hidden md:inline-flex items-center">
      <div class="inline-flex items-center justify-center" *ngFor="let index of indexes; let i = index">
        <span class="text-xs font-extrabold leading-5 mr-1">{{index.label}}:</span>
        <span class="text-xs font-normal leading-5 mr-1">{{index.value}}%&nbsp;{{index.periodo}}</span>
        <i *ngIf="index.description" ngxTippy="{{index.description}}"
        tippyName="tooltip-{{i}}" alt="" class="fal text-blue-600 fa-info-circle"></i>
        &nbsp;
        &nbsp;
        &nbsp;
      </div>
    </span>
  </div>
</div>
