import { ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor(private router: Router, private vps: ViewportScroller) { }

  scrollToTop(): void {
    setTimeout(() => {
      this.vps.scrollToPosition([0, 0]);
      window.scrollTo(0, 0);
    }, 100);
  }

  init(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.scrollToTop();
        }, 0);
      }
    });
  }
}
