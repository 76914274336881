import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss'
})
export class HeroComponent {
  @Input() isLoading: boolean = false;
  buttons: any = {
    card01: { hovered: false },
    card02: { hovered: false },
    card03: { hovered: false },
    card04: { hovered: false },
  };

  constructor(private readonly router: Router) {}

  onButtonClick(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
      this.router.navigate(['dashboard/calculator']);
    }, 1000);
  }

}
