import { Component } from '@angular/core';


@Component({
  selector: 'app-side-home',
  templateUrl: './side-home.component.html',
  styleUrl: './side-home.component.scss'
})
export class SideHomeComponent {
}
