import { Component } from '@angular/core';

@Component({
  selector: 'app-find-investment',
  templateUrl: './find-investment.component.html',
  styleUrl: './find-investment.component.scss'
})
export class FindInvestmentComponent {

}
