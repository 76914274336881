import { AfterViewInit, Component, ComponentRef, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { closeModalEventObject, modalSize } from './modal-primary.service';
import { trigger, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-modal-primary',
  templateUrl: './modal-primary.component.html',
  styleUrl: './modal-primary.component.scss',
  animations:[
    trigger('modalOverlay',[
      transition(':enter',[
        style({opacity:0}),
        animate('250ms', style({ opacity: .5 })),
      ]),
      transition(':leave',[
        animate('300ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('modal',[
      transition(':enter',[
        style({top:-999}),
        animate('250ms', style({ top: '50%' })),
      ]),
      transition(':leave',[
        animate('300ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class ModalPrimaryComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() title: string = '';
  @Input() titleIcon: string = '';
  @Input() titleClass: string = '';
  @Input() size: modalSize = 'modal-md';
  
  @Input() bodyComponent!:ComponentRef<{}>;
  @Input() bodyTemplate!:TemplateRef<{}>;

  @Output() closeModalEvent = new EventEmitter<closeModalEventObject>();

  public modalIdentifier:number;
  public showModal:boolean=true;

  @ViewChild('modalPrimaryContent', {read: ViewContainerRef, static:false}) modalPrimaryContent!:ViewContainerRef;

  constructor(
    private elementRef: ElementRef
  ){}

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  ngAfterViewInit() : void{
    if(this.bodyComponent){
      this.modalPrimaryContent.insert(this.bodyComponent.hostView) ;
    }
    if(this.bodyTemplate){
      this.modalPrimaryContent.createEmbeddedView(this.bodyTemplate) ;
      
    }
  }

  closeModal(){
    this.closeModalEvent.emit({confirm:false});
    this.showModal=false;
    setTimeout(()=>{
      this.elementRef.nativeElement.remove();
    },300)
  }

}
