import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() id = '';
  @Input() icon = '';
  @Input() tabIndex = '';
  @Input() type = 'submit';
  @Input() theme: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quintenary' = 'primary';
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() buttonWidth: 'full' | 'fit' | string = 'full';
  @Input() buttonClass = 'flex items-center justify-center h-10 py-2 px-4 font-light tracking-wide transition-colors duration-300 transform rounded-lg focus:outline-none focus:ring-opacity-80 focus:ring';
  @Input() isLoading = false;
  @Input() isDisabled = false;
}
