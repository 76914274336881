import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/_services/shared.service';
import { MobileMenuItem } from '../mobile-menu/mobile-menu-item.interface';

@Component({
  selector: 'app-navbar-home',
  templateUrl: './navbar-home.component.html',
  styleUrl: './navbar-home.component.scss'
})
export class NavbarHomeComponent implements OnInit {
  load: any = false;
  loading: boolean = false;
  currentRoute: string = '';
  menuItems: Array<MobileMenuItem> = [
    {
      title: 'Página inicial',
      path: '/',
    },
    {
      title: 'Encontre seu investimento',
      path: '/investments',
    },
    {
      title: 'Quem somos',
      path: '/quem-somos',
    },
    {
      title: 'FAQ',
      path: '/faq',
    },
    {
      title: 'Glossário',
      path: '/glossario',
    },
    {
      title: 'Contato',
      path: '/contato',
    },
  ]

  mobileMenuLinks: Array<MobileMenuItem> = [
    {
      title: 'Entre em Contato',
      description: 'contato@planetainvest.com.br',
      path: 'mailto:contato@planetainvest.com.br',
    },
  ]

  constructor(private router: Router, private sharedService: SharedService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url.subscribe(urlSegments => {
      ;
      this.currentRoute = urlSegments.map(segment => segment.path).join('/');
    });
  }

  onButtonClick(): void {
    ;
    if (this.currentRoute != '/') {
      this.scroll();
    } else {
      this.router.navigate(['contato']);
    }
  }


  scroll() {
    this.sharedService.setNavigateToContact(true);
  }
}
