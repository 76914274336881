import { Component } from '@angular/core';

@Component({
  selector: 'app-sidesection',
  templateUrl: './sidesection.component.html',
  styleUrls: ['./sidesection.component.scss']
})
export class SidesectionComponent {

}
