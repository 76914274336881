import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-placeholder',
  templateUrl: './card-placeholder.component.html',
  styleUrl: './card-placeholder.component.scss'
})
export class CardPlaceholderComponent {
  @Input() title?: string = '';
  @Input() text?: string = '';
}
