import { Component, Input } from '@angular/core';
import { AcademyCard } from 'src/app/acedemy/academy-home/academy-home.component';

@Component({
  selector: 'app-academy-horizontal-card',
  templateUrl: './academy-horizontal-card.component.html',
  styleUrl: './academy-horizontal-card.component.scss'
})
export class AcademyHorizontalCardComponent {
  @Input() card!: AcademyCard;
}
