import { Component } from '@angular/core';

@Component({
  selector: 'app-side-academy',
  templateUrl: './side-academy.component.html',
  styleUrls: ['./side-academy.component.scss']
})
export class SideAcademyComponent {

}
