<nav>
  <div class="flex bg-slate-50 border border-b  gap-6 h-auto items-center justify-around shadow">
    <div>
      <img src="assets/images/logo-blue.svg" alt="">
    </div>
    <div class="flex w-auto py-4 gap-2">
      <div class="flex items-center justify-center">
        <a [routerLink]="['/dashboard']" routerLinkActive="text-slate-900" [routerLinkActiveOptions]="{exact:true}"
          class="flex flex-col items-center text-slate-500 hover:text-slate-900 px-4 text-sm font-medium">
          <div class="flex bg-slate-100 rounded-3xl h-8 w-8 items-center justify-center gap-2.5">
            <img src="assets/icons/icon-home.svg" alt="" class="h-5">
          </div>
          <p>Página Inicial</p>
        </a>
        <a [routerLink]="['/dashboard/dreams']" routerLinkActive="text-slate-900"
          [routerLinkActiveOptions]="{exact:true}"
          class="flex flex-col items-center text-slate-500 hover:text-slate-900 px-4 text-sm font-medium">
          <div class="flex h-8 w-8 items-center justify-center gap-2.5">
            <img src="assets/icons/icon-dream.svg" alt="" class="h-6">
          </div>
          Conquiste seu sonho
        </a>
        <a [routerLink]="['/dashboard/finance']" routerLinkActive="text-slate-900"
          [routerLinkActiveOptions]="{exact:true}"
          class="flex flex-col items-center text-slate-500 hover:text-slate-900 px-4 text-sm font-medium">
          <div class="flex h-8 w-8 items-center justify-center gap-2.5">
            <img src="assets\icons\icon-finance.svg" alt="" class="h-6">
          </div>
          Planeje seu sonho
        </a>
        <a href="#" routerLinkActive="text-slate-900" [routerLinkActiveOptions]="{exact:true}"
          class="flex flex-col items-center text-slate-500 hover:text-slate-900 px-4 text-sm font-medium">
          <div class="flex h-8 w-8 items-center justify-center gap-2.5">
            <img src="assets\icons\icon-game.svg" alt="" class="h-6">
          </div>
          Jogue o jogo do investidor
        </a>
        <a href="#" routerLinkActive="text-slate-900" [routerLinkActiveOptions]="{exact:true}"
          class="flex flex-col items-center text-slate-500 hover:text-slate-900 px-4 text-sm font-medium">
          <div class="flex h-8 w-8 items-center justify-center gap-2.5">
            <img src="assets\icons\icon-learn.svg" alt="" class="h-6">
          </div>
          Aprenda a investir
        </a>
      </div>
    </div>
    <button
      class="flex items-center h-10 w-32 px-1 bg-white text-slate-700 text-base border border-slate-200 gap-1 rounded-3xl shadow">
      <div class="bg-slate-50 rounded-full ml-1 mr-2 flex bg-slate-100 rounded-3xl h-6 w-6 items-center justify-center">
        <img src="assets\icons\icon-user.svg" alt="">
      </div>
      Entrar
      <div class="ml-2">
        <img src="assets\icons\icon-arrow-down.svg" alt="" class="h-2">
      </div>
    </button>
  </div>
</nav>